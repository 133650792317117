import image1 from "../images/ao-dai/1.jpg";
import image2 from "../images/ao-dai/2.jpg";
import image3 from "../images/ao-dai/3.jpg";
import image4 from "../images/ao-dai/4.jpg";
import image5 from "../images/ao-dai/5.jpg";
import image6 from "../images/ao-dai/6.jpg";
import image7 from "../images/ao-dai/7.jpg";

export const aoDai = [
  { image: image1 },
  { image: image2 },
  { image: image3 },
  { image: image4 },
  { image: image5 },
  { image: image6 },
  { image: image7 },
];
